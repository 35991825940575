import { autoUpdate, offset, size, useFloating } from '@floating-ui/react';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import Box, { BoxStyleProps } from '../Box';
import Portal from '../Portal';
import Toast, { useToast } from '../Toast';

import PhoneMockup, { PhoneMockupProps } from '../PhoneMockup/PhoneMockup';
import { MIN_WIDTH as TOAST_MIN_WIDTH } from '../Toast';
import styles from './PhoneMockupLayout.module.css';
import { AnimationDirection } from './PhoneMockupLayoutContext';

interface PhoneMockupLayoutProps extends BoxStyleProps {
  className?: string;
  backButton?: ReactNode;
  animationDirection?: AnimationDirection;
}

const PhoneMockupLayout = ({
  className,
  backButton,
  children,
  ...otherProps
}: PropsWithChildren<PhoneMockupLayoutProps>) => {
  const toast = useToast();

  const [shouldFlipToTop, setShouldFlipToTop] = useState(false);

  const OFFSET_TOP = 80;
  const OFFSET_X = 24;

  const { x, y, refs, strategy, update } = useFloating({
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        elementResize: false,
      });
      return cleanup;
    },
    placement: 'right-start',
    middleware: [
      offset({
        crossAxis: OFFSET_TOP,
      }),
      size({
        apply({ elements }) {
          const { x } = elements.reference.getBoundingClientRect();

          const overflowing = x - (TOAST_MIN_WIDTH + OFFSET_X * 2) < 0;

          setShouldFlipToTop(overflowing);
        },
      }),
    ],
  });

  const toastElementStyles = shouldFlipToTop
    ? {}
    : {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      };

  return (
    <Box {...otherProps}>
      <div className={cx(styles['base'], className)}>
        {backButton && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            className={styles['back-button-wrapper']}
          >
            {backButton}
          </motion.div>
        )}
        <AnimatePresence>
          {!!toast.toastProps && (
            <Portal>
              <div
                ref={refs.setFloating}
                className={cx(styles['toast-wrapper'], {
                  [styles['toast-wrapper--top']]: shouldFlipToTop,
                })}
                style={toastElementStyles}
              >
                <Toast key={toast.key} {...toast.toastProps} />
              </div>
            </Portal>
          )}
        </AnimatePresence>
        <div className={styles['main-wrapper']} ref={refs.setReference}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === PhoneMockup) {
              return React.cloneElement(child, {
                onAnimationComplete: update,
              } as PhoneMockupProps);
            }
            return child;
          })}
        </div>
      </div>
    </Box>
  );
};

export default PhoneMockupLayout;
